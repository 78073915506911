/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  installationSite: {},
  error: '',
  errors: null
});

export default function editInstallationSiteReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_EDIT_INSTALLATION_SITE_MODAL': {
      const installationSite = action.payload;
      return INITIAL_STATE.set('isShowing', true).set(
        'installationSite',
        installationSite
      );
    }
    case 'HIDE_EDIT_INSTALLATION_SITE_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_INSTALLATION_SITE_STARTED': {
      return state.set('isLoading', true).set('errors', null);
    }
    case 'EDIT_INSTALLATION_SITE_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'VALIDATE_INSTALLATION_SITE_FAILED': {
      return state.set('isLoading', false).set('errors', action.payload);
    }
    case 'VALIDATE_INSTALLATION_SITE_DONE': {
      return state.set('errors', null);
    }
    case 'EDIT_INSTALLATION_SITE_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_INSTALLATION_SITE_INPUT_CHANGE': {
      return state.setIn(
        ['installationSite', action.payload.name],
        action.payload.value
      );
    }
    default: {
      return state;
    }
  }
}
