/** @format */

import expect from '@edgeguideab/expect';

export default function validateInstallationSite({ installationSite }) {
  return expect(
    {
      companyName: {
        type: 'string',
        allowNull: true,
        condition: (companyName) => companyName.length <= 50,
        errorCode: 'error.validation.max.length'
      },
      locationDescription: {
        type: 'string',
        allowNull: true,
        condition: (locationDescription) => locationDescription.length <= 50,
        errorCode: 'error.validation.max.length'
      },
      street1: {
        type: 'string',
        allowNull: true,
        condition: (street1) => street1.length <= 50,
        errorCode: 'error.validation.max.length'
      },
      street2: {
        type: 'string',
        allowNull: true,
        condition: (street2) => street2.length <= 50,
        errorCode: 'error.validation.max.length'
      },
      country: {
        type: 'string',
        allowNull: true,
        condition: (country) => country.length <= 50,
        errorCode: 'error.validation.max.length'
      },
      city: {
        type: 'string',
        allowNull: true,
        condition: (city) => city.length <= 30,
        errorCode: 'error.validation.max.length'
      },
      zip: {
        type: 'string',
        allowNull: true,
        condition: (zip) => zip.length <= 20,
        errorCode: 'error.validation.max.length'
      }
    },
    installationSite
  ).errors();
}
