/** @format */

import _ from 'lodash';
import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  subscription: getInitialSubscription(),
  validationErrors: {}
});

export default function subscriptionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_SUBSCRIPTION_SUMMARY_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_SUBSCRIPTION_SUMMARY_DONE': {
      const subscription = action.payload;
      subscription.jsonConfiguration = state.getIn([
        'subscription',
        'jsonConfiguration'
      ]);
      subscription.jsonSchema = state.getIn(['subscription', 'jsonSchema']);
      return state
        .set('loading', false)
        .set('subscription', fromJS(subscription));
    }
    case 'LOAD_SUBSCRIPTION_SUMMARY_FAILED':
    case 'ADD_FAVORITE_APIPRODUCTS_FAILED':
    case 'REMOVE_FAVORITE_APIPRODUCTS_FAILED': {
      return INITIAL_STATE.set('error', action.payload.msg).set(
        'loading',
        false
      );
    }
    case 'REMOVE_FAVORITE_APIPRODUCTS_SUCCESS': {
      return state.setIn(['subscription', 'isFavorite'], false);
    }
    case 'ADD_FAVORITE_APIPRODUCTS_SUCCESS': {
      return state.setIn(['subscription', 'isFavorite'], true);
    }
    case 'LOAD_SUBSCRIPTION_CONFIGURATION_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_SUBSCRIPTION_CONFIGURATION_DONE': {
      const subscription = action.payload;
      subscription.jsonSummary = state.getIn(['subscription', 'jsonSummary']);
      return state
        .set('loading', false)
        .set('subscription', fromJS(subscription));
    }
    case 'LOAD_SUBSCRIPTION_CONFIGURATION_FAILED': {
      return INITIAL_STATE.set('error', action.payload.msg).set(
        'loading',
        false
      );
    }
    case 'SUBSCRIPTION_RESET_STATE':
      return INITIAL_STATE;
    case 'SUBSCRIPTION_EDIT_INPUT_CHANGE': {
      const path = action.payload.path + '-.-' + action.payload.name;
      const formData = state
        .getIn(['subscription', 'jsonConfiguration'])
        .toJS();
      _.set(formData, path.split('-.-'), action.payload.value);
      return state
        .setIn(['subscription', 'lastUnsavedChange'], true)
        .setIn(['subscription', 'jsonConfiguration'], fromJS(formData));
    }
    case 'SAVE_SUBSCRIPTION_CONFIGURATION_STARTED': {
      return state.set('validationErrors', fromJS({})).set('loading', true);
    }
    case 'SAVE_SUBSCRIPTION_CONFIGURATION_DONE': {
      return state.merge(INITIAL_STATE);
    }
    case 'SAVE_SUBSCRIPTION_CONFIGURATION_FAILED': {
      const errors = {
        generalError: action.payload.generalError,
        msg: action.payload.msg,
        ...action.payload,
        errorText: ''
      };
      if (action.payload.errorText && action.payload.errorText['*']) {
        errors.errorText = action.payload.errorText['*'][0];
      }
      return state
        .set('validationErrors', fromJS(errors))
        .set('loading', false);
    }
    case 'VALIDATE_SUBSCRIPTION_CONFIGURATION_STARTED': {
      return state.set('validationErrors', fromJS({}));
    }
    case 'VALIDATE_SUBSCRIPTION_CONFIGURATION_FAILED': {
      return state.set('validationErrors', fromJS(action.payload));
    }
    case 'RESET_SUBSCRIPTION_ERRORS': {
      return state.set('validationErrors', fromJS({}));
    }
    default: {
      return state;
    }
  }
}

function getInitialSubscription() {
  return {
    name: '',
    id: 0,
    createdAt: '',
    folderId: 0,
    jsonConfiguration: Map(),
    jsonSchema: Map(),
    jsonSummary: Map(),
    lastUnsavedChange: '',
    shares: []
  };
}
