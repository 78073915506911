/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  applicationName: 'Elevator',
  form: {
    elevatorId: '',
    alarmTransmitterName: '',
    locationOfTheTerminal: '',
    name: ''
  },
  error: ''
});

export default function editAdditionalInformationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_EDIT_ADDITIONAL_INFORMATION_MODAL': {
      const summary = action.payload;
      return INITIAL_STATE.set('isShowing', true)
        .set('applicationName', summary.get('applicationName'))
        .setIn(['form', 'elevatorId'], summary.get('elevatorId'))
        .setIn(
          ['form', 'alarmTransmitterName'],
          summary.get('alarmTransmitterName')
        )
        .setIn(
          ['form', 'locationOfTheTerminal'],
          summary.get('locationOfTheTerminal')
        )
        .setIn(['form', 'name'], summary.get('name'));
    }
    case 'HIDE_EDIT_ADDITIONAL_INFORMATION_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_ADDITIONAL_INFORMATION_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_ADDITIONAL_INFORMATION_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_ADDITIONAL_INFORMATION_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_ADDITIONAL_INFORMATION_INPUT_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
