/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  service: {},
  error: '',
  failedForwardingIdFaultReason: '',
  errors: null
});

export default function editArcServiceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_EDIT_ARC_SERVICE_MODAL': {
      return INITIAL_STATE.set('isShowing', true).set(
        'service',
        action.payload.service
      );
    }
    case 'HIDE_EDIT_ARC_SERVICE_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_ARC_SERVICE_STARTED': {
      return state.set('isLoading', true).set('errors', null);
    }
    case 'VALIDATE_ARC_SERVICE_FAILED': {
      return state
        .set('isLoading', false)
        .set('errors', fromJS(action.payload));
    }
    case 'VALIDATE_ARC_SERVICE_DONE': {
      return state.set('errors', null);
    }
    case 'EDIT_ARC_SERVICE_DONE': {
      if (action.payload?.failedForwardingIdFaultReason) {
        state = state.set(
          'failedForwardingIdFaultReason',
          action.payload.failedForwardingIdFaultReason
        );
      }
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_ARC_SERVICE_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_ARC_SERVICE_INPUT_CHANGE': {
      if (action.payload.name === 'isCustom') {
        if (action.payload.value === 'true') {
          return state.setIn(['service', 'isCustom'], true);
        } else {
          return state.setIn(['service', 'isCustom'], false);
        }
      }
      return state.setIn(
        ['service', action.payload.name],
        action.payload.value
      );
    }
    default: {
      return state;
    }
  }
}
