/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';
import validateArcService from './validation/arcService';

export default function editArcService() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_ARC_SERVICE_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const isAlarmsDirect =
      state.configuration.getIn([
        'currentSubscription',
        'subscription',
        'jsonSummary',
        'subscriptionTypeName'
      ]) === 'Alarms Direct';

    const service = state.modals.getIn(['editArcService', 'service']);

    if (isAlarmsDirect) {
      const errors = validateArcService({
        service: service.toJS()
      });

      await Promise.resolve();

      if (Object.keys(errors).length) {
        return dispatch({
          type: 'VALIDATE_ARC_SERVICE_FAILED',
          payload: errors
        });
      }
      dispatch({
        type: 'VALIDATE_ARC_SERVICE_DONE'
      });
    }

    let response;
    try {
      response = await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveArcService`,
        {
          body: {
            service: service,
            subscriptionId
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_ARC_SERVICE_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );
    dispatch({
      type: 'EDIT_ARC_SERVICE_DONE',
      payload: response.body
    });
  };
}
