/** @format */

import config from 'config.json';
import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import urls from 'utils/urls';
import DualtechLoader from 'components/global/DualtechLoader';
import configurationRoutesFactory from './configuration';
import organizationRoutesFactory from './organization';
import eagleEyeRoutesFactory from './eagleEye';

const Vpn = lazy(() => import('components/views/Vpn'));
const VpnCustom = lazy(() => import('components/views/Vpn/Custom'));
const Things = lazy(() => import('components/views/Things'));
const ThingsDetail = lazy(() => import('components/views/Things/Detail'));
const SimCardView = lazy(() => import('components/views/SimCard/View'));
const SimCardStatusView = lazy(() => import('components/views/SimCard/Status'));
const TermsAndConditions = lazy(
  () => import('components/views/TermsAndConditions')
);
const ErrorView = lazy(() => import('components/views/ErrorView'));
const HomeView = lazy(() => import('components/views/Home'));
const ManualsView = lazy(() => import('components/views/Manuals/ManualsView'));
const ManualsEdgeManagerView = lazy(
  () => import('components/views/Manuals/ManualsEdgeManagerView')
);
const FirmwareContainer = lazy(() => import('components/views/Firmware'));
const KernelContainer = lazy(
  () => import('components/views/Kernel/KernelViewContainer')
);
const MobileModemFirmwareContainer = lazy(
  () =>
    import(
      'components/views/MobileModemFirmware/MobileModemFirmwareViewContainer'
    )
);
const LoginContainer = lazy(
  () => import('components/views/Login/LoginContainer')
);

const PasswordRecoveryContainer = lazy(
  () => import('components/views/PasswordRecovery/PasswordRecoveryContainer')
);

const UserActivationContainer = lazy(
  () => import('components/views/User/Activation/UserActivationContainer')
);
const UserContainer = lazy(
  () => import('components/views/User/View/UserContainer')
);
const UserListAdminContainer = lazy(
  () => import('components/views/User/List/UserListAdminContainer')
);
const RegisterContainer = lazy(
  () => import('components/views/Register/RegisterContainer')
);
const SimCardListContainer = lazy(
  () => import('components/views/SimCard/List/SimCardListContainer')
);
const NewsArticle = lazy(() => import('components/views/News/NewsArticle'));
const AllNews = lazy(() => import('components/views/News/AllNews'));
const StartPage = lazy(() => import('components/views/Start/StartPage'));

const MobileLanguageContainer = lazy(
  () => import('components/views/MobileLanguage/MobileLanguageContainer')
);
const Documents = lazy(() => import('components/views/Documents'));

const ServiceLogsContainer = lazy(
  () => import('components/views/ServiceLogs/ServiceLogsContainer')
);

const Maintenance = lazy(() => import('components/views/Maintenance'));

const NewLogin = lazy(() => import('components/views/LandingPage'));

const NewPasswordRecovery = lazy(
  () => import('components/views/NewPasswordRecovery')
);

const RegistrationSchema = lazy(
  () => import('components/views/Configuration/RegistrationSchema')
);

const TwoFactorAuthentication = lazy(
  () => import('components/views/TwoFactorAuthentication/TwoFactorAuth')
);

const OrganizationSuspended = lazy(
  () => import('components/views/OrganizationSuspended')
);

const TerminalStatus = lazy(
  () =>
    import(
      'components/views/Configuration/View/SubscriptionStatus/TerminalStatus'
    )
);

export default function routes(checkSession) {
  function withSession(WrappedComponent, ...input) {
    return function ComponentWithSession({ ...props }) {
      useEffect(() => {
        checkSession(input, props);
      });

      return <WrappedComponent {...props} />;
    };
  }

  return (
    <Suspense
      fallback={
        <div style={{ textAlign: 'center' }}>
          <DualtechLoader />
        </div>
      }
    >
      <Switch>
        {eagleEyeRoutesFactory((component) =>
          withSession(component, { ewmFeature: 'eagleEye' })
        )}
        {configurationRoutesFactory(withSession)}
        {organizationRoutesFactory(withSession)}
        <Route
          key={urls.home}
          path={urls.home}
          component={config.homePage !== 'addsecure' ? HomeView : NewLogin}
        />
        <Route exact key={urls.error} path={urls.error} component={ErrorView} />
        <Route
          exact
          key={urls.maintenance}
          path={urls.maintenance}
          component={Maintenance}
        />
        <Route
          exact
          key={urls.termsAndConditions}
          path={urls.termsAndConditions}
          component={TermsAndConditions}
        />
        <Route
          exact
          key={urls.mobileLanguage}
          path={urls.mobileLanguage}
          component={MobileLanguageContainer}
        />
        <Route
          exact
          key={urls.register}
          path={urls.register}
          component={RegisterContainer}
        />
        <Route
          exact
          key={urls.loginOTC}
          path={urls.loginOTC}
          component={
            config.homePage !== 'addsecure'
              ? LoginContainer
              : TwoFactorAuthentication
          }
        />
        <Route key={urls.manuals} path={urls.manuals} component={ManualsView} />
        <Route
          key={urls.manualsEdgeManager}
          path={urls.manualsEdgeManager}
          component={withSession(ManualsEdgeManagerView)}
        />
        <Route
          exact
          key={urls.allNews}
          path={urls.allNews}
          component={withSession(AllNews)}
        />
        <Route
          key={urls.latestNews}
          path={urls.latestNews}
          component={withSession(NewsArticle)}
        />
        <Route
          key={urls.newsArticle}
          path={urls.newsArticle}
          component={withSession(NewsArticle)}
        />
        <Route
          exact
          key={urls.login}
          path={urls.login}
          component={
            config.homePage !== 'addsecure' ? LoginContainer : NewLogin
          }
        />
        <Route
          key={urls.firmware}
          path={urls.firmware}
          component={withSession(FirmwareContainer)}
        />
        <Route
          exact
          key={urls.kernel}
          path={urls.kernel}
          component={withSession(KernelContainer)}
        />
        <Route
          exact
          key={urls.mobileModemFirmware}
          path={urls.mobileModemFirmware}
          component={withSession(MobileModemFirmwareContainer)}
        />
        <Route
          exact
          key={urls.user}
          path={urls.user}
          component={withSession(UserContainer)}
        />
        <Route
          exact
          key={urls.userActivation}
          path={urls.userActivation}
          component={UserActivationContainer}
        />
        <Route
          exact
          key={urls.userList}
          path={urls.userList}
          component={withSession(UserListAdminContainer)}
        />
        <Route
          exact
          key={urls.showUser}
          path={urls.showUser}
          component={withSession(UserContainer)}
        />
        <Route
          exact
          key={urls.passwordRecovery}
          path={urls.passwordRecovery}
          component={
            config.homePage !== 'addsecure'
              ? PasswordRecoveryContainer
              : NewPasswordRecovery
          }
        />
        <Route
          exact
          key={urls.ownSimCardList}
          path={urls.ownSimCardList}
          component={withSession(SimCardListContainer)}
        />
        <Route
          exact
          key={urls.simCardList}
          path={urls.simCardList}
          component={withSession(SimCardListContainer)}
        />
        <Route
          exact
          key={urls.startPage}
          path={urls.startPage}
          component={withSession(StartPage)}
        />
        <Route
          exact
          key={urls.vpn}
          path={urls.vpn}
          component={withSession(Vpn)}
        />
        <Route
          exact
          key={urls.vpnCustom}
          path={urls.vpnCustom}
          component={withSession(VpnCustom)}
        />
        <Route
          exact
          key={urls.things}
          path={urls.things}
          component={withSession(Things)}
        />
        <Route
          exact
          key={urls.thingsConnections}
          path={urls.thingsConnections}
          component={withSession(Things)}
        />
        <Route
          exact
          key={urls.thingsDetail}
          path={urls.thingsDetail}
          component={withSession(ThingsDetail)}
        />
        <Route
          exact
          key={urls.connectionsDetail}
          path={urls.connectionsDetail}
          component={withSession(ThingsDetail)}
        />
        <Route
          exact
          key={urls.simCardView}
          path={urls.simCardView}
          component={withSession(SimCardView)}
        />
        <Route
          exact
          key={urls.simCardStatusView}
          path={urls.simCardStatusView}
          component={withSession(SimCardStatusView)}
        />
        <Route
          key={urls.documents}
          path={urls.documents}
          component={withSession(Documents)}
        />
        <Route
          key={urls.serviceLogs}
          path={urls.serviceLogs}
          component={withSession(ServiceLogsContainer)}
        />
        <Route
          key={urls.terminalStatus}
          path={urls.terminalStatus}
          component={withSession(TerminalStatus)}
        />
        <Route
          key={urls.registrationSchema}
          path={urls.registrationSchema}
          component={withSession(RegistrationSchema)}
        />
        <Route
          key={urls.organizationSuspended}
          path={urls.organizationSuspended}
          component={OrganizationSuspended}
        />
        <Redirect path='*' to={urls.home} />
      </Switch>
    </Suspense>
  );
}
