/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  form: {
    path: null,
    deviceTypeId: ''
  },
  error: '',
  formError: false
});

export default function editSubscriptionDetailsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_EDIT_SUBSCRIPTION_DETAILS_MODAL': {
      const summary = action.payload;
      return INITIAL_STATE.set('isShowing', true)
        .setIn(['form', 'path'], summary.get('path'))
        .setIn(['form', 'deviceTypeId'], summary.get('deviceTypeId'));
    }
    case 'HIDE_EDIT_SUBSCRIPTION_DETAILS_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_FAILED': {
      return state
        .set('isLoading', false)
        .set('formError', action.payload.formError)
        .set('error', action.payload.error);
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_INPUT_CHANGE': {
      if (action.payload.name === 'deviceTypeId') {
        const deviceType = action.payload.deviceTypes?.find(
          (d) => d.get('deviceTypeId') === action.payload.value
        );
        if (
          !deviceType
            .get('supportedPaths')
            .includes(state.getIn(['form', 'path']))
        ) {
          state = state.setIn(['form', 'path'], null);
        }
      }
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
