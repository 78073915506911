/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';

export default function editSubscriptionDetails() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_SUBSCRIPTION_DETAILS_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const form = state.modals.getIn(['editSubscriptionDetails', 'form']);

    if (!form.get('path') || form.get('path') === 'select') {
      return dispatch({
        type: 'EDIT_SUBSCRIPTION_DETAILS_FAILED',
        payload: {
          formError: true,
          error: 'error.path.required'
        }
      });
    }

    try {
      await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveSubscriptionDetails`,
        {
          body: {
            subscriptionId,
            form
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_SUBSCRIPTION_DETAILS_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );

    dispatch({
      type: 'EDIT_SUBSCRIPTION_DETAILS_DONE'
    });
  };
}
