/** @format */

import expect from '@edgeguideab/expect';
import isIp from 'is-ip';

export default function validateArcService({ service }) {
  return expect(
    {
      accountNumber: {
        type: 'string',
        allowNull: false,
        condition: (accountNumber) => /^[\x20-\x7F]*$/.test(accountNumber),
        errorCode: 'validation.required.field'
      },
      installationIpAddress: {
        type: 'string',
        allowNull: false,
        condition: (ip) => isIp(ip),
        errorCode: 'validation.not.valid.ip'
      },
      ersPhoneNumber: {
        type: 'string',
        allowNull: true,
        condition: (ersPhoneNumber) => validateErsPhoneNumber(ersPhoneNumber),
        errorCode: 'validation.not.valid.ers.phone.number'
      }
    },
    service
  ).errors();
}

function validateErsPhoneNumber(input) {
  const regex = /^\d{1,23}$/;
  return regex.test(input);
}
